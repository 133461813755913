import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard';
// layouts
import { DashboardLayout } from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';
// ----------------------------------------------------------------------

const GrowIntelPage = lazy(() => import('src/pages/grow-intel/grow-intel'));
const GrowIntelRestaurantPage = lazy(
  () => import('src/pages/grow-intel/grow-intel-restaurant')
);

// ----------------------------------------------------------------------

export const growRomaniaRoutes = [
  {
    path: paths?.growIntel?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths?.growIntel.root,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.competitors,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.item_search,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      // {
      //     path: paths?.growIntel.market_changes,
      //     hasChildren: true,
      //     element: (
      //     <CheckPermission permissionRequired={RequiredPerm?.GrowIntel} isGrowIntelEnable>
      //         <GrowIntelPage />
      //     </CheckPermission>
      //     ),
      // },
      {
        path: paths?.growIntel.menu_suggestion,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.menu_analysis,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.popular_brands,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel.popular_items,
        hasChildren: true,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.growIntel?.grow_intel_restaurant,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.GrowIntel}
            isGrowIntelEnable
          >
            <GrowIntelRestaurantPage />
          </CheckPermission>
        )
      }
    ]
  }
];
