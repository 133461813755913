import { memo } from 'react';

function CalendarCampaignIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='64'
        height='64'
        viewBox='0 0 64 64'
        fill='none'
      >
        <path
          d='M20.4427 6.62593C20.4427 5.17567 19.267 4 17.8167 4C16.3665 4 15.1908 5.17567 15.1908 6.62593V9.25186C15.1908 10.7021 16.3665 11.8778 17.8167 11.8778C19.267 11.8778 20.4427 10.7021 20.4427 9.25186V6.62593Z'
          fill='url(#paint0_linear_744_15120)'
        />
        <path
          d='M49.3295 6.62593C49.3295 5.17567 48.1538 4 46.7036 4C45.2533 4 44.0776 5.17567 44.0776 6.62593V9.25186C44.0776 10.7021 45.2533 11.8778 46.7036 11.8778C48.1538 11.8778 49.3295 10.7021 49.3295 9.25186V6.62593Z'
          fill='url(#paint1_linear_744_15120)'
        />
        <path
          d='M6 23.6947V53.8929C6 56.7932 8.35152 59.1447 11.2519 59.1447H53.2667C56.167 59.1447 58.5186 56.7932 58.5186 53.8929V23.6947H6ZM21.7556 49.954C21.7556 51.4048 20.5805 52.5799 19.1296 52.5799H16.5037C15.0529 52.5799 13.8778 51.4048 13.8778 49.954V47.3281C13.8778 45.8772 15.0529 44.7021 16.5037 44.7021H19.1296C20.5805 44.7021 21.7556 45.8772 21.7556 47.3281V49.954ZM21.7556 35.5114C21.7556 36.9622 20.5805 38.1373 19.1296 38.1373H16.5037C15.0529 38.1373 13.8778 36.9622 13.8778 35.5114V32.8854C13.8778 31.4346 15.0529 30.2595 16.5037 30.2595H19.1296C20.5805 30.2595 21.7556 31.4346 21.7556 32.8854V35.5114ZM36.1982 49.954C36.1982 51.4048 35.0231 52.5799 33.5722 52.5799H30.9463C29.4955 52.5799 28.3204 51.4048 28.3204 49.954V47.3281C28.3204 45.8772 29.4955 44.7021 30.9463 44.7021H33.5722C35.0231 44.7021 36.1982 45.8772 36.1982 47.3281V49.954ZM36.1982 35.5114C36.1982 36.9622 35.0231 38.1373 33.5722 38.1373H30.9463C29.4955 38.1373 28.3204 36.9622 28.3204 35.5114V32.8854C28.3204 31.4346 29.4955 30.2595 30.9463 30.2595H33.5722C35.0231 30.2595 36.1982 31.4346 36.1982 32.8854V35.5114ZM50.6408 49.954C50.6408 51.4048 49.4657 52.5799 48.0148 52.5799H45.3889C43.9381 52.5799 42.763 51.4048 42.763 49.954V47.3281C42.763 45.8772 43.9381 44.7021 45.3889 44.7021H48.0148C49.4657 44.7021 50.6408 45.8772 50.6408 47.3281V49.954ZM50.6408 35.5114C50.6408 36.9622 49.4657 38.1373 48.0148 38.1373H45.3889C43.9381 38.1373 42.763 36.9622 42.763 35.5114V32.8854C42.763 31.4346 43.9381 30.2595 45.3889 30.2595H48.0148C49.4657 30.2595 50.6408 31.4346 50.6408 32.8854V35.5114Z'
          fill='url(#paint2_linear_744_15120)'
        />
        <path
          d='M58.5186 21.0685V13.1907C58.5186 10.2904 56.167 7.93884 53.2667 7.93884H51.9537V9.25181C51.9537 12.1482 49.5983 14.5037 46.7019 14.5037C43.8055 14.5037 41.45 12.1482 41.45 9.25181V7.93884H23.0685V9.25181C23.0685 12.1482 20.7131 14.5037 17.8167 14.5037C14.9203 14.5037 12.5648 12.1482 12.5648 9.25181V7.93884H11.2519C8.35152 7.93884 6 10.2904 6 13.1907V21.0685H58.5186Z'
          fill='url(#paint3_linear_744_15120)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_744_15120'
            x1='15.8615'
            y1='4.74519'
            x2='21.2004'
            y2='9.80606'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#22C55E' />
            <stop offset='1' stopColor='#03B344' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_744_15120'
            x1='44.7483'
            y1='4.74519'
            x2='50.0872'
            y2='9.80606'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#22C55E' />
            <stop offset='1' stopColor='#03B344' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_744_15120'
            x1='12.7068'
            y1='27.0481'
            x2='31.3488'
            y2='66.3173'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#22C55E' />
            <stop offset='1' stopColor='#03B344' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_744_15120'
            x1='12.7068'
            y1='9.18083'
            x2='15.7463'
            y2='26.4683'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#22C55E' />
            <stop offset='1' stopColor='#03B344' />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}

export default memo(CalendarCampaignIcon);
