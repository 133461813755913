import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import { Label } from 'src/components/label';
import { SvgColor } from 'src/components/svg-color';
import { getClientCountry } from 'src/auth/context/jwt/utils';
import { RequiredPerm } from '../../routes/permissions';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  ads: icon('ic_ads'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  operations: icon('ic_operations'),
  intel: icon('ic_intel'),
  marketChange: icon('ic_market_change'),
  popularBrands: icon('ic_popular_brands'),
  popularItems: icon('ic_popular_items'),
  itemSearch: icon('ic_item_search'),
  competitors: icon('ic_job'),
  menuSuggest: icon('ic_menu_suggestion'),
  menuAnalysis: icon('ic_menu_analysis'),
  academy: icon('ic_academy')
};

const ROMANIA_MODULES = [
  'home',
  'finance',
  'ads',
  'overview',
  'economics',
  'discount',
  'profile',
  'summary',
  'calendar',
  'budget',
  'growdash academy',
  'analyze my menu',
  'menu suggestion',
  'competitors',
  'popular brands',
  'popular items',
  'item search'
];

// ----------------------------------------------------------------------

export function useNavData() {
  const userCountry = getClientCountry();
  const data = useMemo(() => {
    const navItems = [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'pulse',
        items: [
          {
            title: 'overview',
            path: paths.dashboard.dashboard,
            icon: ICONS.dashboard,
            permissions: RequiredPerm?.Dashboard,
            hasChildren: true
          },
          {
            title: 'Economics',
            path: paths.dashboard.finance,
            icon: ICONS.ecommerce,
            permissions: RequiredPerm?.Finance
          }
        ]
      },
      {
        subheader: 'campaigns',
        items: [
          {
            title: 'summary',
            path: paths.marketing.root,
            icon: ICONS.external,
            permissions: RequiredPerm?.Marketing
          },
          {
            title: 'ads',
            path: paths.marketing.ads,
            icon: ICONS.ads,
            permissions: RequiredPerm?.Ads,
            hasChildren: true
          },
          {
            title: 'discount',
            path: paths.marketing.discount,
            icon: ICONS.invoice,
            permissions: RequiredPerm?.Discount,
            hasChildren: true
          },
          {
            title: 'calendar',
            path: paths.marketing.calendar,
            icon: ICONS.calendar,
            permissions: RequiredPerm?.Calendar
          }
        ]
      },
      {
        subheader: 'GrowSocial 💎',
        items: [
          {
            title: 'GrowSocial',
            path: paths.growSocial.root,
            icon: ICONS.academy,
            permissions: RequiredPerm?.GrowSocial
          }
        ]
      },
      // GROW INTEL
      {
        subheader: 'growIntel 💎',
        info: <Label color='info'>NEW</Label>,
        items: [
          {
            title: 'Analyze My Menu',
            path: paths.growIntel.menu_analysis,
            icon: ICONS.menuAnalysis,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Menu Suggestion',
            path: paths.growIntel.menu_suggestion,
            icon: ICONS.menuSuggest,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Competitors',
            path: paths.growIntel.competitors,
            icon: ICONS.competitors,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Popular Brands',
            path: paths.growIntel.popular_brands,
            icon: ICONS.popularBrands,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Popular Items',
            path: paths.growIntel.popular_items,
            icon: ICONS.popularItems,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Market Change',
            path: paths.growIntel.market_changes,

            icon: ICONS.marketChange,
            permissions: RequiredPerm?.GrowIntel
          },
          {
            title: 'Item Search',
            path: paths.growIntel.item_search,
            icon: ICONS.itemSearch,
            permissions: RequiredPerm?.GrowIntel
          }
        ]
      },
      // OPERATIONS
      {
        subheader: 'Operations',
        items: [
          {
            title: 'Performance',
            path: paths.operations.root,
            icon: ICONS.operations,
            permissions: RequiredPerm?.Operations_Overview
          },
          {
            title: 'Feedback',
            path: paths.operations.reviews_and_rating,
            icon: ICONS.label,
            permissions: RequiredPerm?.ReviewsAndRatings
          }
        ]
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: 'profile',
            path: paths.management.profile,
            icon: ICONS.user,
            permissions: RequiredPerm?.Profile
          },
          {
            title: 'budget',
            path: paths.management.budget,
            icon: ICONS.banking,
            permissions: RequiredPerm?.Budget
          }
        ]
      },
      {
        subheader: 'Resource',
        items: [
          {
            title: 'Growdash Academy',
            path:
              userCountry.toLowerCase() === 'romania'
                ? paths.explore.growthhub
                : paths.explore.growthhub_extended,
            icon: ICONS.academy
          }
        ]
      }
    ];

    if (userCountry.toLowerCase() === 'romania') {
      return navItems
        .map(section => ({
          ...section,
          items: section.items.filter(item =>
            ROMANIA_MODULES.includes(item.title.toLowerCase())
          )
        }))
        .filter(section => section.items.length > 0);
    }

    return navItems;
  }, [userCountry]);

  return data;
}
