/* eslint-disable no-console */
import {
  UserCreate,
  UpdateCommisionRequest,
  UpdateFoodCostRequest,
  OtherCostRequest,
  CommisionCreateRequest
} from 'src/utils/interfaces';
import axios, { endpoints } from '../../../utils/axios';

export const ManagementService = {
  AddUser: async (user: UserCreate): Promise<any[]> => {
    try {
      const response = await axios.post(endpoints?.MANAGEMENT.ADD_USER, user);
      return response.data;
    } catch (error) {
      console.error('Error in Create Client', error);
      throw error;
    }
  },
  GetUsers: async (
    page: number,
    take: number,
    status?: string
  ): Promise<any[]> => {
    try {
      const response = await axios.get(endpoints?.MANAGEMENT.GET_USERS, {
        params: { page, take, status }
      });
      return response.data;
    } catch (error) {
      console.error('Error in fetch client', error);
      throw error;
    }
  },
  DeleteClients: async (id: number): Promise<any[]> => {
    try {
      const response = await axios.delete(
        endpoints?.MANAGEMENT.DELETE_CLIENT.replace('{id}', id.toString())
      );
      return response.data;
    } catch (error) {
      console.error('Error in delete client', error);
      throw error;
    }
  },
  CreateCommission: async (data: CommisionCreateRequest): Promise<any> => {
    try {
      const response = await axios.post(
        endpoints.MANAGEMENT.CREATE_COMMISSIONS,
        data
      );
      return response.data;
    } catch (error) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  getAllCommission: async (
    page: number,
    take: number,
    brand_ids?: number[]
  ) => {
    try {
      const response = await axios.get(endpoints.MANAGEMENT.GET_COMMISSIONS, {
        params: { page, take, brand_ids }
      });
      return response.data;
    } catch (error) {
      console.error('Error in get commissions:', error);
      throw error;
    }
  },
  UpdateCommission: async (
    data: UpdateCommisionRequest,
    id: number
  ): Promise<any> => {
    try {
      const response = await axios.patch(
        endpoints.MANAGEMENT.UPDATE_COMMISSION.replace('{id}', id.toString()),
        data
      );
      return response.data;
    } catch (error) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  DeleteCommission: async (id: number): Promise<any[]> => {
    try {
      const response = await axios.delete(
        endpoints?.MANAGEMENT.DELETE_COMMISSION.replace('{id}', id.toString())
      );
      return response.data;
    } catch (error) {
      console.error('Error in delete client', error);
      throw error;
    }
  },
  getAllFoodCost: async (page: number, take: number, id?: number[]) => {
    try {
      const response = await axios.get(endpoints.MANAGEMENT.GET_FOOD_COST, {
        params: { page, take, id }
      });
      return response.data;
    } catch (error) {
      console.error('Error in get commissions:', error);
      throw error;
    }
  },
  UpdateFoodCost: async (
    data: UpdateFoodCostRequest,
    id: number
  ): Promise<any> => {
    try {
      const response = await axios.patch(
        endpoints.MANAGEMENT.UPDATE_FOOD_COST.replace('{id}', id.toString()),
        data
      );
      return response.data;
    } catch (error) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  getAllOtherCost: async (page: number, take: number, brand_ids?: number[]) => {
    try {
      const response = await axios.get(endpoints.MANAGEMENT.OTHER_COST, {
        params: { page, take, brand_ids }
      });
      return response.data;
    } catch (error) {
      console.error('Error in get commissions:', error);
      throw error;
    }
  },
  AddOtherCost: async (data: OtherCostRequest): Promise<any[]> => {
    try {
      const response = await axios.post(endpoints?.MANAGEMENT.OTHER_COST, data);
      return response.data;
    } catch (error) {
      console.error('Error in Create Client', error);
      throw error;
    }
  },
  UpdateOtherCost: async (data: OtherCostRequest, id: number): Promise<any> => {
    try {
      const response = await axios.patch(
        endpoints.MANAGEMENT.OTHER_COST_DETAILS.replace('{id}', id.toString()),
        data
      );
      return response.data;
    } catch (error) {
      console.error('Error in Update Campaign:', error);
      throw error;
    }
  },
  DeleteOtherCost: async (id: number): Promise<any[]> => {
    try {
      const response = await axios.delete(
        endpoints?.MANAGEMENT.OTHER_COST_DETAILS.replace('{id}', id.toString())
      );
      return response.data;
    } catch (error) {
      console.error('Error in delete client', error);
      throw error;
    }
  },
  SubscribeEmailNotification: async (user: any): Promise<any[]> => {
    try {
      const response = await axios.put(
        endpoints?.MANAGEMENT.EMAIL.SUBSCRIBE.replace(
          '{id}',
          user.id.toString()
        )
      );
      return response.data;
    } catch (error) {
      console.error('Error in email subscription', error);
      throw error;
    }
  },
  UnSubscribeEmailNotification: async (user: any): Promise<any[]> => {
    try {
      const response = await axios.put(
        endpoints?.MANAGEMENT.EMAIL.UNSUBSCRIBE.replace(
          '{id}',
          user.id.toString()
        )
      );
      return response.data;
    } catch (error) {
      console.error('Error in email unsubscription', error);
      throw error;
    }
  },

  fetchSubscriptionStatus: async () => {
    try {
      const response = await axios.get(endpoints.SUBSCRIPTION.status);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      throw error;
    }
  },

  fetchCreditNotes: async () => {
    try {
      const response = await axios.get(endpoints.SUBSCRIPTION.creditnotes);
      return response.data;
    } catch (error) {
      console.error('Error fetching credit notes:', error);
      throw error;
    }
  },

  fetchSubscriptionPortal: async () => {
    try {
      const response = await axios.get(endpoints.SUBSCRIPTION.portal);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription portal:', error);
      throw error;
    }
  }
};
