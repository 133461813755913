import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AnalyticsBrowser } from '@june-so/analytics-next';

export function useJune(writeKey: string) {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | undefined>(
    undefined
  );

  useEffect(() => {
    const loadAnalytics = async () => {
      const response = AnalyticsBrowser.load({
        writeKey
      });
      setAnalytics(response as AnalyticsBrowser);
    };
    loadAnalytics();
  }, [writeKey]);

  return analytics;
}
