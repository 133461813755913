import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard';
// layouts
import { DashboardLayout } from 'src/layouts/dashboard';
// components
import { SplashScreen } from 'src/components/loading-screen';
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';

// ----------------------------------------------------------------------

// OVERVIEW
const MarketingOverviewPage = lazy(
  () => import('src/pages/marketing/overview')
);
const AdsAppPage = lazy(() => import('src/pages/marketing/ads'));
const AdsDetailAppPage = lazy(() => import('src/pages/marketing/ads-detail'));
const CalendarPage = lazy(() => import('src/pages/marketing/calendder'));
const DiscountPage = lazy(() => import('src/pages/marketing/discount'));
const RBDCDiscountDetailPage = lazy(
  () => import('src/pages/marketing/rbdc-discount-details')
);
const EditCampaignPage = lazy(
  () => import('src/pages/marketing/edit-campaign')
);
const CreateCampaignPage = lazy(
  () => import('src/pages/marketing/create-campaign')
);
const CreateDiscountCampaignPage = lazy(
  () => import('src/pages/marketing/create-discount-campaign')
);
const AreaPerformanceView = lazy(
  () => import('src/pages/marketing/ads-area-performance')
);
// ----------------------------------------------------------------------

export const marketingRoutes = [
  {
    path: paths?.marketing?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Marketing}>
            <MarketingOverviewPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.marketing?.ads,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <AdsAppPage />
          </CheckPermission>
        )
      },
      {
        path: 'ads/:id',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <AdsDetailAppPage />
          </CheckPermission>
        )
      },
      {
        path: 'ads/area-performance',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <AreaPerformanceView />
          </CheckPermission>
        )
      },
      {
        path: 'ads/campaign/edit/:id',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <EditCampaignPage />
          </CheckPermission>
        )
      },
      {
        path: 'ads/campaign/create',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Ads}>
            <CreateCampaignPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.marketing?.discount,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Discount}>
            <DiscountPage />
          </CheckPermission>
        )
      },
      {
        path: 'discount/rbdc/:id',
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Discount}>
            <RBDCDiscountDetailPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.marketing?.calendar,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Calendar}>
            <CalendarPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.marketing?.discount_campaign_add,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Discount}>
            <CreateDiscountCampaignPage />
          </CheckPermission>
        )
      }
    ]
  }
];
