import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function ToolsTableIcon({ ...other }: BoxProps) {
  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M1 8H5C5.26522 8 5.51957 8.10536 5.70711 8.29289C5.89464 8.48043 6 8.73478 6 9V17C6 17.2652 5.89464 17.5196 5.70711 17.7071C5.51957 17.8946 5.26522 18 5 18H3C2.23479 18 1.49849 17.7077 0.941739 17.1827C0.384993 16.6578 0.0498925 15.9399 0.00500012 15.176L4.66045e-09 15V9C4.66045e-09 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8ZM18 9V15C18 15.7652 17.7077 16.5015 17.1827 17.0583C16.6578 17.615 15.9399 17.9501 15.176 17.995L15 18H9C8.73478 18 8.48043 17.8946 8.29289 17.7071C8.10536 17.5196 8 17.2652 8 17V9C8 8.73478 8.10536 8.48043 8.29289 8.29289C8.48043 8.10536 8.73478 8 9 8H17C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9ZM15 4.66045e-09C15.7652 -4.26217e-05 16.5015 0.292325 17.0583 0.817284C17.615 1.34224 17.9501 2.06011 17.995 2.824L18 3V5C18 5.26522 17.8946 5.51957 17.7071 5.70711C17.5196 5.89464 17.2652 6 17 6H9C8.73478 6 8.48043 5.89464 8.29289 5.70711C8.10536 5.51957 8 5.26522 8 5V1C8 0.734784 8.10536 0.48043 8.29289 0.292893C8.48043 0.105357 8.73478 4.66045e-09 9 4.66045e-09H15ZM6 1V5C6 5.26522 5.89464 5.51957 5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 4.66045e-09 5.26522 4.66045e-09 5V3C-4.26217e-05 2.23479 0.292325 1.49849 0.817284 0.941739C1.34224 0.384993 2.06011 0.0498925 2.824 0.00500012L3 4.66045e-09H5C5.26522 4.66045e-09 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1Z'
        fill='#02D2C5'
      />
    </Box>
  );
}

export default memo(ToolsTableIcon);
