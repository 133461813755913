import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function PlStatementIcon({ ...other }: BoxProps) {
  const color = 'currentColor';

  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M20.955 2.9025C20.49 2.6775 19.9575 2.745 19.5525 3.06L18.3675 4.0125L16.515 2.5425C16.0425 2.1675 15.3825 2.1525 14.9025 2.5125L12.96 3.9375L11.0775 2.52C10.5975 2.16 9.93754 2.16 9.46504 2.52L7.68754 3.885L6.87004 3.165C6.47254 2.82 5.92504 2.745 5.44504 2.955C4.96504 3.18 4.66504 3.6375 4.66504 4.1625V17.385H2.98504C2.59504 17.385 2.29504 17.7 2.29504 18.075C2.29504 20.085 3.93004 21.72 5.93254 21.72L17.9775 21.75H17.985C18.0375 21.75 18.0975 21.7425 18.15 21.7275C20.1225 21.6825 21.705 20.0625 21.705 18.0825V4.095C21.705 3.585 21.42 3.12 20.955 2.9025ZM8.41504 13.23C7.98004 13.23 7.62754 12.8775 7.62754 12.45V11.9175C7.62754 11.4825 7.98004 11.1375 8.41504 11.1375H8.78254V10.875C8.78254 10.62 8.99254 10.41 9.25504 10.41C9.51004 10.41 9.72004 10.62 9.72004 10.875V11.1225H10.2825C10.545 11.1225 10.755 11.3325 10.755 11.5875C10.755 11.85 10.545 12.06 10.2825 12.06H8.57254V12.2925H10.0875C10.5225 12.2925 10.875 12.6375 10.875 13.0725V13.605C10.875 14.0325 10.5225 14.385 10.0875 14.385H9.72004V14.625C9.72004 14.8875 9.51004 15.0975 9.25504 15.0975C8.99254 15.0975 8.78254 14.8875 8.78254 14.625V14.385H8.09254C7.83754 14.385 7.62004 14.175 7.62004 13.9125C7.62004 13.6575 7.83754 13.4475 8.09254 13.4475H9.93004V13.215H8.41504V13.23ZM5.93254 20.34C4.93504 20.34 4.08004 19.68 3.78004 18.7725H14.475C14.595 19.365 14.85 19.905 15.21 20.355L5.93254 20.34ZM17.8425 14.895H12.975C12.585 14.895 12.285 14.58 12.285 14.205C12.285 13.815 12.5925 13.515 12.975 13.515H17.8425C18.225 13.515 18.5325 13.8225 18.5325 14.205C18.5325 14.5875 18.2175 14.895 17.8425 14.895ZM17.8425 11.865H12.975C12.585 11.865 12.285 11.55 12.285 11.175C12.285 10.785 12.5925 10.485 12.975 10.485H17.8425C18.225 10.485 18.5325 10.7925 18.5325 11.175C18.5325 11.5575 18.2175 11.865 17.8425 11.865ZM17.8425 8.8275H8.92504C8.54254 8.8275 8.23504 8.52 8.23504 8.1375C8.23504 7.755 8.55004 7.4475 8.92504 7.4475H17.835C18.2175 7.4475 18.525 7.7625 18.525 8.1375C18.5325 8.5275 18.2175 8.8275 17.8425 8.8275Z'
        fill={color}
      />
    </Box>
  );
}

export default memo(PlStatementIcon);
