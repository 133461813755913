import { m } from 'framer-motion';
import { useCallback, useState } from 'react';
// @mui
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { alpha, styled } from '@mui/material/styles';
import axios, { endpoints } from 'src/utils/axios';
// routes
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// components
import { UserSwitchIcon } from 'src/assets/icons';
import {
  jwtDecode,
  removeCookie,
  setSession
} from 'src/auth/context/jwt/utils';
import { varHover } from 'src/components/animate';
import { CustomPopover, usePopover } from 'src/components/custom-popover';
import { useSnackbar } from 'src/components/snackbar';
// eslint-disable-next-line import/no-extraneous-dependencies
import posthog from 'posthog-js';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';
import { Iconify } from 'src/components/iconify';
import { useJune } from 'src/useJune';
import { useAuthContext } from '../../auth/hooks';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

export interface AllClientsProps {
  id: number;
  name: string;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  width: '100% !important',
  backgroundColor: 'transparent',
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
    padding: 0
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: 'transparent',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent'
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover
        }
    }
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative'
  }
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export default function AccountPopover() {
  const router = useRouter();
  const { user, logout } = useAuthContext();
  const [isSwitchReady, setSwitchReady] = useState<boolean>(false);
  const [allClients, setAllClients] = useState<AllClientsProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const loading = allClients.length === 0;
  const juneHook = useJune('q3ClmW5XJpuu6QHF') as any;

  const pathlocation = useLocation();

  const handleResetFilters = useCallback(() => {
    const path = pathlocation?.pathname || window.location.pathname;
    router.push(path);
  }, [pathlocation, router]);

  const popover = usePopover();

  const getClients = useCallback(async () => {
    try {
      if (user?.isCustomerSuccessUser) {
        const response = await axios.get(endpoints.AUTH.GET_CSM_CLIENTS);
        setAllClients(response.data);
      } else {
        const response = await axios.get(endpoints.AUTH.GET_ALL_CLIENTS);
        setAllClients(response.data);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelChangeToken = useCallback(
    async (id: number) => {
      handleResetFilters();
      try {
        const response = await axios.post(endpoints.AUTH.GET_CLIENT_TOKEN, {
          newClientId: id
        });
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token } = response.data;
        if (access_token) {
          setSession(access_token);
          const tokenUser = jwtDecode(access_token);
          if (juneHook) {
            juneHook.identify(tokenUser.username.toLowerCase(), {
              email: tokenUser.username,
              CSUser: tokenUser.name,
              country: tokenUser.country_name,
              isCustomerSuccessorUser: tokenUser.isCustomerSuccessUser
                ? 'Yes'
                : 'No'
            });
            juneHook.track(
              'CS visited Client',
              {
                visitedClient: tokenUser.client_name,
                CS_user: tokenUser.name
              },
              {
                // Add the GROUP_ID here to track this event on behalf of a workspace
                context: {
                  groupId: `Customer Service`
                }
              }
            );
          }
          window.location.reload();
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
    [enqueueSnackbar, handleResetFilters, juneHook]
  );

  const handleLogout = async () => {
    ReactGA.set({ userId: null });
    logout();
    posthog.reset();
    removeCookie();
    router.push(paths?.auth?.jwt?.login);
  };

  const handleSwitchClient = async () => {
    getClients();
    setSwitchReady(true);
  };

  const handleGoCreditNotes = async () => {
    router.push(paths.management.credit_notes);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: theme => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: theme =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
          })
        }}
      >
        <Avatar
          src='/assets/icons/components/ic_avatar.svg'
          alt='gd'
          sx={{
            width: 56,
            height: 56,
            border: theme => `solid 2px ${theme.palette.background.default}`
          }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={() => {
          popover.onClose();
          setSwitchReady(false);
        }}
        sx={{ p: 0 }}
      >
        {!isSwitchReady ? (
          <Box sx={{ width: 200, p: 0 }}>
            <Box sx={{ p: 2, pb: 1.5 }}>
              <Typography variant='subtitle2' noWrap>
                {user?.name}
              </Typography>

              <Typography
                variant='body2'
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {user?.username}
              </Typography>
              {(user?.groupId || user?.isCustomerSuccessUser) && (
                <Button
                  variant='contained'
                  // size="small"
                  startIcon={<UserSwitchIcon color='white' />}
                  onClick={() => handleSwitchClient()}
                  sx={{ bgcolor: '#02006D', mt: 1.5, width: '100%' }}
                >
                  Switch Client
                </Button>
              )}
              {user?.isCustomerSuccessUser && (
                <Button
                  variant='contained'
                  onClick={handleGoCreditNotes}
                  sx={{
                    color: '#fff',
                    background: '#30d6bf',
                    '&:hover': {
                      background: '#30d6bf'
                    },
                    marginTop: '10px',
                    width: '100%'
                  }}
                >
                  Credit Notes
                </Button>
              )}
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem
              onClick={handleLogout}
              sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            >
              Logout
            </MenuItem>
          </Box>
        ) : (
          <Box sx={{ width: 360, p: 0 }}>
            <Box sx={{ px: 2, py: 3 }}>
              <Typography variant='h6'>Other Clients</Typography>
              <Typography variant='body2' sx={{ color: '#637381' }}>
                You have {allClients?.length} clients
              </Typography>
            </Box>
            <Autocomplete
              open
              loading={loading}
              id='custom-input-demo'
              options={allClients}
              PopperComponent={PopperComponent}
              noOptionsText='No Client Found'
              getOptionLabel={(option: AllClientsProps) => option.name}
              renderOption={(props, option) => (
                <ListItem
                  disablePadding
                  key={option?.id}
                  onClick={() => handelChangeToken(option.id)}
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          fontWeight: 600,
                          fontSize: '1rem'
                        }}
                      >
                        {option?.name?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option?.name}
                      // secondary={option?.email}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              renderInput={params => (
                <Box sx={{ px: 2, pb: 1 }}>
                  <TextField
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    fullWidth
                    placeholder='Search for client...'
                    // TODO: Fix the type
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Iconify
                            icon='eva:search-fill'
                            sx={{ color: 'text.disabled' }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              )}
            />
          </Box>
        )}
      </CustomPopover>
    </>
  );
}
