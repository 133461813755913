import { memo } from 'react';

interface TotalOrdersIconProps {
  color: string;
}

function TotalOrdersIcon({ color }: TotalOrdersIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <path
        d='M12.0098 18H39.8866L40.8866 14H16.0098V10H43.4482C44.5528 10 45.4482 10.8954 45.4482 12C45.4482 12.1635 45.4282 12.3264 45.3884 12.4851L40.3884 32.485C40.166 33.3754 39.366 34 38.4482 34H10.0098C8.90521 34 8.00977 33.1046 8.00977 32V8H4.00977V4H10.0098C11.1143 4 12.0098 4.89544 12.0098 6V18ZM12.0098 46C9.80063 46 8.00977 44.2092 8.00977 42C8.00977 39.7908 9.80063 38 12.0098 38C14.2189 38 16.0098 39.7908 16.0098 42C16.0098 44.2092 14.2189 46 12.0098 46ZM36.0098 46C33.8006 46 32.0098 44.2092 32.0098 42C32.0098 39.7908 33.8006 38 36.0098 38C38.219 38 40.0098 39.7908 40.0098 42C40.0098 44.2092 38.219 46 36.0098 46Z'
        fill={color}
      />
      <path
        d='M22.5391 22.4609C22.3128 22.4609 22.1289 22.6448 22.1289 22.8711V23.2813H22.9492V22.8711C22.9492 22.6448 22.7654 22.4609 22.5391 22.4609Z'
        fill='white'
      />
      <path
        d='M30.7695 22.4609H30.332V21.2305C30.332 20.552 29.7801 20 29.1016 20H20.8984C20.2199 20 19.668 20.552 19.668 21.2305V22.4609H19.2305C18.552 22.4609 18 23.0129 18 23.6914V29.4336C18 30.1121 18.552 30.6641 19.2305 30.6641H22.9492V29.8437H19.2305C19.0042 29.8437 18.8203 29.6599 18.8203 29.4336V23.6914C18.8203 23.4651 19.0042 23.2812 19.2305 23.2812H19.668V26.1523C19.668 26.8309 20.2199 27.3828 20.8984 27.3828H29.1016C29.7801 27.3828 30.332 26.8309 30.332 26.1523V23.2812H30.7695C30.9958 23.2812 31.1797 23.4651 31.1797 23.6914V29.4336C31.1797 29.6599 30.9958 29.8437 30.7695 29.8437H27.6308L28.4511 29.0234L27.8711 28.4434L26.0606 30.2539L27.8711 32.0643L28.4511 31.4843L27.6308 30.664H30.7695C31.448 30.664 32 30.1121 32 29.4336V23.6914C32 23.0129 31.448 22.4609 30.7695 22.4609ZM23.7695 25.7422H22.9492V24.1016H22.1289V25.7422H21.3086C21.3086 25.4309 21.3086 22.7322 21.3086 22.8711C21.3086 22.1926 21.8606 21.6406 22.5391 21.6406C23.2176 21.6406 23.7695 22.1926 23.7695 22.8711C23.7695 23.5325 23.7695 25.8264 23.7695 25.7422ZM26.2305 25.7422H24.1797V21.6406H25V24.9219H26.2305V25.7422ZM28.6914 25.7422H26.6406V21.6406H27.4609V24.9219H28.6914V25.7422Z'
        fill='white'
      />
    </svg>
  );
}

export default memo(TotalOrdersIcon);
