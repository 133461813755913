import { memo } from 'react';

interface RodiIconProps {
  color: string;
}

function RodiIcon({ color }: RodiIconProps) {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.4687 30.541L22.4563 30.5417C25.8781 30.5417 28.6519 33.3959 28.6519 36.9167L19.013 36.9154L19.0143 38.3334L30.0287 38.3322V36.9167C30.0287 35.3832 29.5906 33.9373 28.8087 32.6656L32.7823 32.6667C35.5253 32.6667 37.8935 34.3174 39 36.7062C35.7442 41.127 30.4719 44 24.5215 44C20.7198 44 17.4992 43.1632 14.8834 41.6982L14.8854 28.5173C16.6003 28.7699 18.1694 29.4871 19.4687 30.541ZM12.1304 27C12.8364 27 13.4184 27.5469 13.4979 28.2515L13.5072 28.4154V41.1667C13.5072 41.949 12.8908 42.5833 12.1304 42.5833H9.37679C8.61642 42.5833 8 41.949 8 41.1667V28.4167C8 27.6343 8.61642 27 9.37679 27H12.1304Z'
          fill={color}
        />
        <path
          d='M16.8795 6.88719C18.9084 5.09064 21.5768 4 24.5 4C30.8512 4 36 9.14873 36 15.5C36 17.9437 35.2378 20.2094 33.9382 22.0722L30.825 15.5H33.7C33.7 10.419 29.5811 6.3 24.5 6.3C21.919 6.3 19.5863 7.36279 17.9156 9.07461L16.8795 6.88719ZM32.1205 24.1128C30.0915 25.9093 27.4232 27 24.5 27C18.1487 27 13 21.8513 13 15.5C13 13.0563 13.7622 10.7907 15.0618 8.92777L18.175 15.5H15.3C15.3 20.581 19.419 24.7 24.5 24.7C27.081 24.7 29.4136 23.6373 31.0843 21.9254L32.1205 24.1128ZM26.1263 18.7528L22.8737 15.5L19.621 18.7528L17.9946 17.1263L22.8737 12.2473L26.1263 15.5L29.379 12.2473L31.0053 13.8737L26.1263 18.7528Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(RodiIcon);
