import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function OtherCostIcon({ ...other }: BoxProps) {
  const color = 'currentColor';

  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M9.89062 21C11.446 21 12.6503 20.8511 13.6162 20.5468C13.5958 20.437 13.582 20.3246 13.582 20.209C13.582 19.6924 13.7968 19.226 14.1401 18.8906C13.7968 18.5553 13.582 18.0889 13.582 17.5723C13.582 17.0556 13.7968 16.5893 14.1401 16.2539C13.7968 15.9185 13.582 15.4522 13.582 14.9355C13.582 14.4189 13.7968 13.9525 14.1401 13.6172C13.7968 13.2818 13.582 12.8155 13.582 12.2988C13.582 11.6885 13.8833 11.1506 14.3413 10.8144C14.0277 10.5273 13.6926 10.2699 13.341 10.0435C13.4838 9.86357 13.582 9.6459 13.582 9.39844C13.582 8.81589 13.1099 8.34375 12.5273 8.34375H7.25391C6.67136 8.34375 6.19922 8.81589 6.19922 9.39844C6.19922 9.64536 6.29713 9.86234 6.43913 10.042C4.39183 11.3609 3 13.7492 3 16.2539C3 20.5082 6.94752 21 9.89062 21ZM9.88499 15.8811C9.01254 15.8811 8.30296 15.1715 8.30296 14.299C8.30296 13.6103 8.74791 13.0293 9.36328 12.8124V12.0352C9.36328 11.7437 9.59921 11.5078 9.89062 11.5078C10.182 11.5078 10.418 11.7437 10.418 12.0352V12.816C11.0276 13.0359 11.467 13.6146 11.467 14.299C11.467 14.5906 11.2311 14.8264 10.9397 14.8264C10.6481 14.8264 10.4123 14.5906 10.4123 14.299C10.4123 14.008 10.1759 13.7717 9.88499 13.7717C9.59399 13.7717 9.35765 14.008 9.35765 14.299C9.35765 14.59 9.59399 14.8264 9.88499 14.8264C10.7573 14.8264 11.467 15.5361 11.467 16.4084C11.467 17.0928 11.0276 17.6716 10.418 17.8916V18.3633C10.418 18.6547 10.182 18.8906 9.89062 18.8906C9.59921 18.8906 9.36328 18.6547 9.36328 18.3633V17.895C8.74791 17.6783 8.30296 17.0971 8.30296 16.4084C8.30296 16.117 8.53876 15.8811 8.83031 15.8811C9.12172 15.8811 9.35765 16.117 9.35765 16.4084C9.35765 16.6994 9.59399 16.9357 9.88499 16.9357C10.1759 16.9357 10.4123 16.6994 10.4123 16.4084C10.4123 16.1174 10.1759 15.8811 9.88499 15.8811Z'
        fill={color}
      />
      <path
        d='M20.2125 19.418H15.4313C14.9944 19.418 14.6403 19.7721 14.6403 20.209C14.6403 20.6458 14.9944 21 15.4313 21H20.2125C20.6494 21 21.0035 20.6458 21.0035 20.209C21.0035 19.7721 20.6494 19.418 20.2125 19.418Z'
        fill={color}
      />
      <path
        d='M20.2125 16.7812H15.4313C14.9944 16.7812 14.6403 17.1354 14.6403 17.5723C14.6403 18.0091 14.9944 18.3633 15.4313 18.3633H20.2125C20.6494 18.3633 21.0035 18.0091 21.0035 17.5723C21.0035 17.1354 20.6494 16.7812 20.2125 16.7812Z'
        fill={color}
      />
      <path
        d='M20.2125 14.1445H15.4313C14.9944 14.1445 14.6403 14.4987 14.6403 14.9355C14.6403 15.3724 14.9944 15.7266 15.4313 15.7266H20.2125C20.6494 15.7266 21.0035 15.3724 21.0035 14.9355C21.0035 14.4987 20.6494 14.1445 20.2125 14.1445Z'
        fill={color}
      />
      <path
        d='M20.2125 11.5078H15.4313C14.9944 11.5078 14.6403 11.862 14.6403 12.2988C14.6403 12.7357 14.9944 13.0898 15.4313 13.0898H20.2125C20.6494 13.0898 21.0035 12.7357 21.0035 12.2988C21.0035 11.862 20.6494 11.5078 20.2125 11.5078Z'
        fill={color}
      />
      <path
        d='M11.3779 4.05469C11.1594 3.4422 10.5796 3 9.89295 3C9.20548 3 8.61895 3.44083 8.40101 4.05469H6.72889C6.55943 4.05469 6.40026 4.13599 6.30097 4.27359C6.2021 4.41106 6.17477 4.58821 6.22887 4.74889L7.05216 7.28906H12.7339L13.557 4.74889C13.6111 4.58821 13.5838 4.41106 13.4849 4.27359C13.3856 4.13599 13.2265 4.05469 13.057 4.05469H11.3779Z'
        fill={color}
      />
    </Box>
  );
}

export default memo(OtherCostIcon);
