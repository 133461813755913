import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function FoodCostIcon({ ...other }: BoxProps) {
  const color = 'currentColor';

  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M12.7786 13.2493C12.7755 13.2482 12.7728 13.2472 12.7698 13.2461V14.3303C12.9839 14.2355 13.1107 14.0338 13.1427 13.843C13.1601 13.7401 13.185 13.3929 12.7786 13.2493Z'
        fill={color}
      />
      <path
        d='M12.5273 10.0312C10.8796 10.0312 9.53906 11.3718 9.53906 13.0195C9.53906 14.6673 10.8796 16.0078 12.5273 16.0078C14.1751 16.0078 15.5156 14.6673 15.5156 13.0195C15.5156 11.3718 14.1751 10.0312 12.5273 10.0312ZM13.6633 13.9305C13.5932 14.3469 13.2715 14.7691 12.7702 14.8831V15.1289C12.7702 15.2746 12.6522 15.3926 12.5066 15.3926C12.3609 15.3926 12.2429 15.2746 12.2429 15.1289V14.9069C11.9897 14.8893 11.7871 14.8278 11.495 14.6367C11.3731 14.557 11.339 14.3936 11.4187 14.2717C11.4984 14.1498 11.6618 14.1156 11.7836 14.1954C11.9677 14.3158 12.0793 14.3617 12.2429 14.3779V13.0387C12.0423 12.9482 11.8942 12.8619 11.7741 12.7677C11.5351 12.5803 11.4262 12.254 11.4899 11.9162C11.5595 11.5468 11.8162 11.2531 12.1599 11.1495C12.188 11.1411 12.2156 11.1338 12.2429 11.1271V10.9102C12.2429 10.7645 12.3609 10.6465 12.5066 10.6465C12.6522 10.6465 12.7702 10.7645 12.7702 10.9102V11.0966C13.0691 11.1401 13.2793 11.2845 13.379 11.3991C13.4747 11.5089 13.4632 11.6754 13.3534 11.7711C13.244 11.8664 13.0782 11.8554 12.9823 11.7466C12.9774 11.7415 12.9075 11.6724 12.7702 11.6349V12.685C12.8321 12.7082 12.8941 12.7306 12.9548 12.7521C13.4746 12.9358 13.7527 13.3983 13.6633 13.9305Z'
        fill={color}
      />
      <path
        d='M12.0087 12.0139C11.982 12.1553 12.0179 12.2884 12.1002 12.3529C12.1419 12.3856 12.1904 12.4173 12.2435 12.448V11.6826C12.1043 11.7543 12.0311 11.895 12.0087 12.0139Z'
        fill={color}
      />
      <path
        d='M18.8906 19.2422C18.8906 19.8247 19.3628 20.2969 19.9453 20.2969C20.5278 20.2969 21 19.8247 21 19.2422V13.5469H18.8906V19.2422Z'
        fill={color}
      />
      <path
        d='M20.3595 3.01363C19.1148 3.30349 18.1875 4.41988 18.1875 5.75286V11.9648C18.1875 12.2561 18.4236 12.4922 18.7148 12.4922H21V3.52874C21 3.19194 20.6876 2.93723 20.3595 3.01363Z'
        fill={color}
      />
      <path
        d='M8.27344 3.52734C8.27344 3.23611 8.03733 3 7.74609 3C7.45486 3 7.21875 3.23611 7.21875 3.52734V6.51562H6.16406V3.52734C6.16406 3.23611 5.92795 3 5.63672 3C5.34548 3 5.10938 3.23611 5.10938 3.52734V6.51562H4.05469V3.52734C4.05469 3.23611 3.81858 3 3.52734 3C3.23611 3 3 3.23611 3 3.52734V7.18166C3 7.97356 3.39579 8.71307 4.05469 9.15234L4.58203 9.50391V19.2422C4.58203 19.8247 5.05421 20.2969 5.63672 20.2969C6.21922 20.2969 6.69141 19.8247 6.69141 19.2422V9.50391L7.21875 9.15234C7.87765 8.71307 8.27344 7.97356 8.27344 7.18166V3.52734Z'
        fill={color}
      />
      <path
        d='M17.131 11.9648V7.47609C15.8821 6.4374 14.2769 5.8125 12.5255 5.8125C11.3764 5.8125 10.2903 6.0819 9.32629 6.56031V7.18166C9.32629 8.32881 8.75645 9.39359 7.80199 10.0299L7.74426 10.0684V18.4116C9.01653 19.5406 10.6908 20.2266 12.5255 20.2266C14.6262 20.2266 16.5168 19.3275 17.8341 17.8935V13.2795C17.4104 12.9954 17.131 12.5122 17.131 11.9648ZM12.5255 17.0625C10.2962 17.0625 8.48254 15.2488 8.48254 13.0195C8.48254 10.7902 10.2962 8.97656 12.5255 8.97656C14.7548 8.97656 16.5685 10.7902 16.5685 13.0195C16.5685 15.2488 14.7548 17.0625 12.5255 17.0625Z'
        fill={color}
      />
    </Box>
  );
}

export default memo(FoodCostIcon);
