import { memo } from 'react';

function ActiveCampaignIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='55'
        height='56'
        viewBox='0 0 55 56'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.1974 34.545C4.81556 34.609 0.358771 30.2095 0.292705 24.7672C0.226629 19.3242 4.57668 14.8173 9.95959 14.752L18.3837 14.6498C18.4628 21.1666 18.7644 28.0067 18.624 34.4428L10.1974 34.545ZM18.7936 38.1878C18.9825 39.827 19.3289 41.4489 19.8584 42.9945C20.6597 45.3337 21.9333 47.6208 23.7863 49.2729C24.1428 49.591 24.2869 50.0412 24.2002 50.5116L23.4355 54.6648C23.3531 55.1122 23.0795 55.4565 22.6638 55.6318C22.2358 55.8122 21.8119 55.7672 21.4288 55.5045C18.3861 53.4178 16.1448 50.3245 14.6633 46.9544C13.4582 44.2134 12.73 41.2573 12.4193 38.2652L18.7936 38.1878ZM22.0835 14.1663L22.3335 34.7587C32.7743 36.9242 41.5102 43.1075 49.8477 48.2216C50.857 48.8408 52.1108 48.4278 53.0741 47.7666C54.0376 47.1054 54.556 46.1293 54.5423 45.0014L54.0376 3.42351C54.0239 2.29962 53.4858 1.33908 52.512 0.70082C51.5381 0.0626658 50.3514 -0.196346 49.2878 0.31086C41.8149 3.87644 32.3337 11.309 22.0835 14.1663Z'
          fill='url(#paint0_linear_744_15104)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_744_15104'
            x1='5.1873'
            y1='7.14763'
            x2='50.9852'
            y2='61.305'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#0ECCE8' />
            <stop offset='1' stopColor='#0BBBD5' />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}

export default memo(ActiveCampaignIcon);
