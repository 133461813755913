import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard';
// layouts
import { DashboardLayout } from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/overview'));
const OverviewDetailPage = lazy(
  () => import('src/pages/dashboard/overview-details')
);
const FinancePage = lazy(() => import('src/pages/dashboard/finance'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: paths?.dashboard?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <IndexPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.dashboard,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <IndexPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.overview_details,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <OverviewDetailPage />
          </CheckPermission>
        )
      },
      {
        path: paths?.dashboard?.finance,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Finance}>
            <FinancePage />
          </CheckPermission>
        )
      }
    ]
  }
];
export const oldDashboardRoutes = [
  {
    path: paths?.dashboard?.root_old,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <IndexPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.dashboard_old,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Dashboard}>
            <Navigate replace to={paths?.dashboard?.dashboard} />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths?.dashboard?.finance_old,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Finance}>
            <Navigate replace to={paths?.dashboard?.finance} />
          </CheckPermission>
        )
      }
    ]
  }
];
