/* eslint-disable no-console */
import axios, { endpoints } from './axios';

// const getSession = () => sessionStorage.getItem('accessToken' || '') || null;
// const session = getSession();

// const decodedToken = session ? jwtDecode(session || '') : {};

export const fetchChannels = async () => {
  try {
    const response = await axios.get(endpoints.UTILITY.OPTIONS.CHANNELS);
    return response.data;
  } catch (error) {
    console.error('Error fetching channels:', error);
    throw error;
  }
};
export const fetchLocations = async () => {
  try {
    const response = await axios.get(endpoints.UTILITY.OPTIONS.LOCATIONS);
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
};
export const fetchAreas = async (isGrowIntelEnable: boolean) => {
  if (isGrowIntelEnable) {
    try {
      const response = await axios.get(endpoints.UTILITY.OPTIONS.AREAS);
      return response.data.areas;
    } catch (error) {
      console.error('Error fetching areas:', error);
      throw error;
    }
  }
  return [];
};
export const fetchCuisines = async (
  isGrowIntelEnable: boolean,
  aggregator?: string
) => {
  if (isGrowIntelEnable) {
    try {
      const response = await axios.get(
        endpoints.UTILITY.OPTIONS.CUISINES(aggregator)
      );
      return response.data.cuisines;
    } catch (error) {
      console.error('Error fetching cuisines:', error);
      throw error;
    }
  }
  return [];
};
export const fetchCities = async (
  isGrowIntelEnable: boolean,
  aggreagtor?: string
) => {
  if (isGrowIntelEnable) {
    try {
      const response = await axios.get(
        endpoints.UTILITY.OPTIONS.CITIES(aggreagtor)
      );
      return response.data.cities;
    } catch (error) {
      console.error('Error fetching cities:', error);
      throw error;
    }
  }
  return [];
};
