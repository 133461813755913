// eslint-disable-next-line import/no-extraneous-dependencies
import posthog from 'posthog-js';

const PH_CONFIG = {
  api_host: process.env.REACT_APP_PH_API_HOST as string,
  person_profiles: 'identified_only'
};

const initPostHog = () => {
  if (
    !window.location.host.includes('127.0.0.1') &&
    !window.location.host.includes('localhost')
  ) {
    posthog.init(
      process.env.REACT_APP_PH_API_KEY as string,
      PH_CONFIG as { api_host: string; [key: string]: string }
    );
  }

  return posthog;
};

export const posthogInstance = initPostHog();
