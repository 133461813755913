export default function AppBar() {
  return {
    MuiAppBar: {
      defaultProps: {
        color: 'transparent'
      },

      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    }
  };
}
