import { ProductFruits } from 'react-product-fruits';
import { jwtDecode } from 'src/auth/context/jwt/utils';

export default function productFruitsOnboarding() {
  const getSession = () => sessionStorage.getItem('accessToken' || '') || null;
  const session = getSession();

  const decodedToken = session ? jwtDecode(session || '') : {};

  const userInfo = {
    username: decodedToken.username || '',
    email: decodedToken.username || ''
  };

  const PF_KEY: string =
    process.env.REACT_APP_PRODUCT_FRUITS_KEY?.toString() || '';
  return (
    <>
      {session ? (
        <ProductFruits workspaceCode={PF_KEY} language='en' user={userInfo} />
      ) : (
        <></>
      )}
    </>
  );
}
