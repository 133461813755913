import { memo } from 'react';
import IconButton from '@mui/material/IconButton';

function TrendingFlat() {
  return (
    <IconButton
      sx={{ bgcolor: '#919EAB29', mx: 0.5 }}
      size='small'
      aria-label='up'
    >
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2 8.00309C2 8.00309 3.05297 8.7439 3.875 8.93552C5.48238 9.31021 6.33282 7.92632 8 8.00309C9.38718 8.06696 9.99049 9.03114 11.375 8.93552C12.4865 8.85875 14 8.00309 14 8.00309'
          stroke='#637381'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </IconButton>
  );
}

export default memo(TrendingFlat);
