import { memo } from 'react';

interface RoasIconProps {
  color: string;
}

function RoasIcon({ color }: RoasIconProps) {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
      >
        <path
          d='M19.4687 30.541L22.4563 30.5417C25.8781 30.5417 28.6519 33.3959 28.6519 36.9167L19.013 36.9154L19.0143 38.3334L30.0287 38.3322V36.9167C30.0287 35.3832 29.5906 33.9373 28.8087 32.6656L32.7823 32.6667C35.5253 32.6667 37.8935 34.3174 39 36.7062C35.7442 41.127 30.4719 44 24.5215 44C20.7198 44 17.4992 43.1632 14.8834 41.6982L14.8854 28.5173C16.6003 28.7699 18.1694 29.4871 19.4687 30.541ZM12.1304 27C12.8364 27 13.4184 27.5469 13.4979 28.2515L13.5072 28.4154V41.1667C13.5072 41.949 12.8908 42.5833 12.1304 42.5833H9.37679C8.61642 42.5833 8 41.949 8 41.1667V28.4167C8 27.6343 8.61642 27 9.37679 27H12.1304Z'
          fill={color}
        />
        <path
          d='M32.4609 19.0679C34.2358 14.8554 32.6428 9.87928 28.5892 7.53389C25.66 5.83903 22.1861 5.94618 19.4456 7.51216L18.307 5.51516C21.7327 3.55773 26.075 3.42379 29.7365 5.54237C34.8887 8.52345 36.8609 14.9046 34.4605 20.2249L36 21.1156L31.2214 23.6614L31.0321 18.2411L32.4609 19.0679ZM15.5391 11.9321C13.7642 16.1446 15.3573 21.1207 19.4108 23.466C22.34 25.161 25.8138 25.0538 28.5542 23.488L29.6928 25.4849C26.2672 27.4423 21.925 27.5762 18.2635 25.4576C13.1113 22.4765 11.1391 16.0954 13.5395 10.7751L12 9.88437L16.7786 7.33858L16.968 12.7589L15.5391 11.9321Z'
          fill={color}
        />
        <path
          d='M28.1538 14.0891V10.5789C28.1538 10.2592 27.9013 10 27.5897 10H27.0256C25.9095 11.1455 23.8118 11.7874 22.5128 12.0916V18.3294C23.8118 18.6337 25.9095 19.2755 27.0256 20.4211H27.5897C27.9013 20.4211 28.1538 20.1619 28.1538 19.8421V16.3319C28.6404 16.2034 29 15.75 29 15.2105C29 14.671 28.6404 14.2176 28.1538 14.0891ZM19.1282 12.3158C18.5051 12.3158 18 12.8342 18 13.4737V16.9474C18 17.5869 18.5051 18.1053 19.1282 18.1053H19.6923L20.2564 21H21.3846V12.3158H19.1282Z'
          fill={color}
        />
      </svg>
    </>
  );
}

export default memo(RoasIcon);
