/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type CalendarInitialProps = {
  events: any;
  resources: any;
};
const initialState: CalendarInitialProps = {
  events: [],
  resources: []
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setEvents: (state, action) => {
      const { payload } = action;
      state.events = payload;
    },
    updateEvent: (state, action) => {
      const { id, eventData } = action.payload;
      const eventIndex = state.events.findIndex(
        (event: any) => event.id === id
      );
      if (eventIndex !== -1) {
        state.events[eventIndex] = {
          ...state.events[eventIndex],
          ...eventData
        };
      }
    },
    addEvent: (state, action) => {
      const nEvent = action.payload;
      state.events.push(nEvent);
    },
    deleteEvent: (state, action) => {
      const { eventId } = action.payload;
      state.events = state.events.filter((event: any) => event.id !== eventId);
    },
    setResources: (state, action) => {
      const { payload } = action;
      state.resources = payload;
    }
  }
});

const { reducer, actions } = calendarSlice;
export const { setEvents, updateEvent, addEvent, setResources, deleteEvent } =
  actions;
export default reducer;
