import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function PlDailyIcon({ ...other }: BoxProps) {
  const color = 'currentColor';

  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <g clipPath='url(#clip0_3171_26681)'>
        <path
          d='M21.8438 12C21.8438 8.89336 19.3254 6.375 16.2188 6.375H16.1753C18.05 7.77084 19.0313 9.99525 19.0313 12V19.0312H16.9219L20.4375 24L24 19.0312H21.8438V12Z'
          fill={color}
        />
        <path
          d='M0 4.96875H2.15625V12C2.15625 15.1066 4.67461 17.625 7.78125 17.625H7.82475C5.95003 16.2292 4.96875 14.0048 4.96875 12V4.96875H7.07812L3.5625 0L0 4.96875Z'
          fill={color}
        />
        <path
          d='M12 6.375C8.89336 6.375 6.375 8.89336 6.375 12C6.375 15.1065 8.89336 17.625 12 17.625C15.1066 17.625 17.625 15.1065 17.625 12C17.625 8.89336 15.1066 6.375 12 6.375ZM12 11.2969C13.1632 11.2969 14.1094 12.2431 14.1094 13.4062C14.1094 14.3217 13.5198 15.0949 12.7031 15.3861V16.2188H11.2969V15.3861C10.4802 15.0949 9.89062 14.3217 9.89062 13.4062H11.2969C11.2969 13.7942 11.6121 14.1094 12 14.1094C12.3879 14.1094 12.7031 13.7942 12.7031 13.4062C12.7031 13.0183 12.3879 12.7031 12 12.7031C10.8368 12.7031 9.89062 11.7569 9.89062 10.5938C9.89062 9.67828 10.4802 8.90512 11.2969 8.61389V7.78125H12.7031V8.61389C13.5198 8.90512 14.1094 9.67828 14.1094 10.5938H12.7031C12.7031 10.2058 12.3879 9.89062 12 9.89062C11.6121 9.89062 11.2969 10.2058 11.2969 10.5938C11.2969 10.9817 11.6121 11.2969 12 11.2969Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3171_26681'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </Box>
  );
}

export default memo(PlDailyIcon);
