import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function ClassicCPCIcon({ ...other }: BoxProps) {
  const color = 'currentColor';

  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path d='M1.2002 21.207H13.8121V23.0087H1.2002V21.207Z' fill={color} />
      <path
        d='M12.0075 19.3911C12.0075 19.1522 11.9126 18.923 11.7437 18.7541C11.5747 18.5851 11.3456 18.4902 11.1067 18.4902H3.89988C3.66095 18.4902 3.43182 18.5851 3.26288 18.7541C3.09393 18.923 2.99902 19.1522 2.99902 19.3911V20.3066H12.0075V19.3911Z'
        fill={color}
      />
      <path
        d='M13.4608 12.0254C13.041 12.4727 12.689 12.9791 12.416 13.5285L19.8309 20.9434C19.9999 21.1122 20.229 21.2071 20.4678 21.2071C20.7067 21.2071 20.9358 21.1122 21.1047 20.9434L22.3788 19.6693C22.4624 19.5857 22.5288 19.4864 22.574 19.3771C22.6193 19.2678 22.6426 19.1506 22.6426 19.0323C22.6426 18.914 22.6193 18.7969 22.574 18.6876C22.5288 18.5783 22.4624 18.479 22.3788 18.3953L14.9638 10.9824C14.4136 11.2531 13.9069 11.6046 13.4608 12.0254Z'
        fill={color}
      />
      <path
        d='M9.00155 7.56713C8.32031 8.19935 7.53172 8.70492 6.67285 9.06007L11.3289 13.7161C11.6872 12.8587 12.1933 12.0709 12.8241 11.3885C13.5054 10.7564 14.294 10.2507 15.1528 9.89539L10.4965 5.24023C10.1381 6.0973 9.63207 6.88486 9.00155 7.56713Z'
        fill={color}
      />
      <path
        d='M12.1812 1.83539L10.9074 3.10919C10.5557 3.46094 10.5557 4.03124 10.9074 4.383L16.0026 9.47821C16.3544 9.82996 16.9247 9.82996 17.2764 9.47821L18.5502 8.20441C18.902 7.85266 18.902 7.28236 18.5502 6.9306L13.455 1.83539C13.1033 1.48364 12.533 1.48364 12.1812 1.83539Z'
        fill={color}
      />
      <path
        d='M4.53864 9.47797L3.26483 10.7518C2.91308 11.1035 2.91308 11.6738 3.26483 12.0256L8.36005 17.1208C8.7118 17.4725 9.2821 17.4725 9.63385 17.1208L10.9077 15.847C11.2594 15.4952 11.2594 14.9249 10.9077 14.5732L5.81244 9.47797C5.46069 9.12621 4.89039 9.12621 4.53864 9.47797Z'
        fill={color}
      />
    </Box>
  );
}

export default memo(ClassicCPCIcon);
