import { memo, useState, useCallback } from 'react';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
//
import { Iconify } from 'src/components/iconify';
import { NavSectionProps, NavListProps, NavConfigProps } from '../types';
import { navVerticalConfig } from '../config';
import { StyledSubheader } from './styles';
import { useAuthContext } from '../../../auth/hooks';
import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionVertical({ data, config, sx, ...other }: NavSectionProps) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionVertical);

// ----------------------------------------------------------------------

type GroupProps = {
  subheader: string;
  items: NavListProps[];
  config: NavConfigProps;
};

function Group({ subheader, items, config }: GroupProps) {
  const [open, setOpen] = useState(true);
  const { user } = useAuthContext();

  const handleToggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const hasPermission = (requiredPermission: string[]) => {
    if (!requiredPermission || user?.isCustomerSuccessUser) {
      return true;
    }
    return user?.permissions?.some((perm: string) =>
      requiredPermission?.includes(perm)
    );
  };

  const renderContent = items.map(
    (list: any) =>
      hasPermission(list?.permissions) && (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={list.hasChildren}
          config={config}
          disabled={!hasPermission(list?.permissions)}
        />
      )
  );

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          {!renderContent.every(item => item === false) && (
            <StyledSubheader
              sx={
                subheader.includes('growIntel') ||
                subheader.includes('GrowSocial')
                  ? {
                      color: '	#daa520',
                      paddingBottom: '13px',
                      background: '#4549500d',
                      width: '100%',
                      fontSize: '12px'
                    }
                  : undefined
              }
              disableGutters
              disableSticky
              onClick={handleToggle}
              config={config}
            >
              <Iconify
                width={16}
                icon={
                  !open
                    ? 'eva:arrow-ios-forward-fill'
                    : 'eva:arrow-ios-downward-fill'
                }
              />
              {subheader}
            </StyledSubheader>
          )}

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  );
}
