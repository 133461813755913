import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useJune } from 'src/useJune';

// ----------------------------------------------------------------------

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ href, ...other }, ref) => {
    const analytics = useJune('q3ClmW5XJpuu6QHF') as any;

    const trackSignIn = () => {
      if (analytics) {
        analytics.track('Navigation: Page Visit', {
          page: href
        });
      }
    };

    return (
      <Link
        ref={ref}
        to={href}
        {...other}
        onClick={() => {
          trackSignIn();
        }}
      />
    );
  }
);

export default RouterLink;
