import { memo } from 'react';

function DailyNewCustomerIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M7.1098 19.85L5.9299 19.5316L5.26245 18.5078C5.03163 18.1538 4.51175 18.1535 4.28078 18.5078L3.61333 19.5316L2.43343 19.85C2.02535 19.9601 1.86449 20.4544 2.13008 20.7836L2.89749 21.7347L2.83573 22.9553C2.81432 23.3775 3.23478 23.6831 3.62989 23.5323L4.77163 23.0963L5.91337 23.5323C6.30774 23.6827 6.72902 23.3785 6.70754 22.9553L6.64578 21.7347L7.41319 20.7836C7.67858 20.4546 7.51811 19.9602 7.1098 19.85Z'
        fill='white'
      />
      <path
        d='M21.5662 19.85L20.3863 19.5316L19.7188 18.5078C19.488 18.1538 18.9681 18.1535 18.7371 18.5078L18.0697 19.5316L16.8898 19.85C16.4817 19.9601 16.3209 20.4544 16.5864 20.7836L17.3538 21.7347L17.2921 22.9553C17.2707 23.3775 17.6911 23.6831 18.0863 23.5323L19.228 23.0963L20.3697 23.5323C20.7642 23.6827 21.1853 23.3781 21.1639 22.9553L21.1021 21.7347L21.8696 20.7836C22.135 20.4546 21.9745 19.9602 21.5662 19.85Z'
        fill='white'
      />
      <path
        d='M15.5163 18.4645L13.5832 17.9429L12.4898 16.2656C12.259 15.9116 11.7391 15.9113 11.5081 16.2656L10.4146 17.9429L8.4816 18.4645C8.07348 18.5747 7.91266 19.069 8.17824 19.3981L9.43549 20.9564L9.33428 22.956C9.31287 23.3782 9.73326 23.6839 10.1284 23.5331L11.999 22.8189L13.8695 23.5331C14.2638 23.6835 14.6851 23.3789 14.6636 22.956L14.5624 20.9564L15.8197 19.3981C16.0851 19.0692 15.9246 18.5747 15.5163 18.4645Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.22698 2.66116C9.22689 3.18759 9.38946 3.70223 9.69411 4.13998C9.99877 4.57774 10.4318 4.91895 10.9385 5.12046C11.4453 5.32197 12.0028 5.37474 12.5408 5.27209C13.0787 5.16943 13.5729 4.91597 13.9608 4.54375C14.3486 4.17153 14.6128 3.69728 14.7198 3.18097C14.8268 2.66466 14.7719 2.12948 14.5621 1.64311C14.3522 1.15674 13.9967 0.741038 13.5407 0.448565C13.0846 0.156093 12.5485 -9.38941e-06 12 1.69957e-09C11.2646 -2.52046e-05 10.5593 0.280326 10.0393 0.779385C9.51925 1.27844 9.22706 1.95534 9.22698 2.66116ZM18 12.6149C18 15.795 6 15.795 6 12.6149C5.99947 11.655 6.24923 10.7101 6.72653 9.86647C7.20383 9.02284 7.89349 8.30725 8.73267 7.78492C8.78758 7.75076 8.85433 7.73882 8.91837 7.7517C8.98241 7.76458 9.03856 7.80123 9.07456 7.85368L12 12.1291L14.9253 7.85353C14.9613 7.80107 15.0175 7.7644 15.0816 7.75152C15.1456 7.73864 15.2124 7.7506 15.2673 7.78478C16.1065 8.30714 16.7962 9.02275 17.2735 9.8664C17.7508 10.7101 18.0005 11.6549 18 12.6149ZM11.4597 6.85653H11.309C11.2391 6.85626 11.1702 6.87193 11.108 6.90223C11.0457 6.93254 10.9918 6.97662 10.9507 7.03084C10.9097 7.08506 10.8827 7.14787 10.8719 7.21409C10.8611 7.2803 10.8669 7.34804 10.8887 7.41169L11.0978 8.02939C11.1271 8.11794 11.186 8.19476 11.2653 8.24795L11.0802 9.573L12 10.9173L12.9198 9.57295L12.7347 8.24791C12.814 8.19472 12.8729 8.1179 12.9022 8.02934L13.1113 7.41164C13.1331 7.34799 13.1389 7.28026 13.1281 7.21404C13.1173 7.14782 13.0903 7.08502 13.0493 7.0308C13.0082 6.97657 12.9543 6.93249 12.892 6.90218C12.8298 6.87188 12.7609 6.85621 12.691 6.85648L11.4597 6.85653Z'
        fill='white'
      />
    </svg>
  );
}

export default memo(DailyNewCustomerIcon);
