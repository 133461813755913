import { memo } from 'react';
import IconButton from '@mui/material/IconButton';

function TrendingUp({
  color = '#22C55E',
  bgcolor = '#22c55e29'
}: {
  color?: string;
  bgcolor?: string;
}) {
  return (
    <>
      <IconButton sx={{ bgcolor, mx: 0.5 }} size='small' aria-label='up'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M14 4.66667C14.0063 4.62021 14.0063 4.57312 14 4.52667C13.9942 4.48753 13.9829 4.44938 13.9666 4.41333C13.949 4.38075 13.929 4.34955 13.9066 4.32C13.8813 4.27784 13.8498 4.23965 13.8133 4.20667L13.7333 4.16C13.6948 4.1313 13.6521 4.10881 13.6066 4.09333H13.4733C13.4327 4.054 13.3852 4.02237 13.3333 4H9.99996C9.63177 4 9.33329 4.29848 9.33329 4.66667C9.33329 5.03486 9.63177 5.33333 9.99996 5.33333H11.8866L9.21995 8.47333L6.33995 6.76C6.05815 6.59239 5.6963 6.65458 5.48662 6.90667L2.15329 10.9067C2.03992 11.0427 1.98534 11.2183 2.0016 11.3946C2.01786 11.571 2.10362 11.7336 2.23995 11.8467C2.3599 11.9461 2.51085 12.0003 2.66662 12C2.86491 12.0003 3.05305 11.9124 3.17995 11.76L6.14662 8.2L8.99329 9.90667C9.27202 10.072 9.62928 10.0129 9.83996 9.76667L12.6666 6.46667V8C12.6666 8.36819 12.9651 8.66667 13.3333 8.66667C13.7015 8.66667 14 8.36819 14 8V4.66667Z'
            fill={color}
          />
        </svg>
      </IconButton>
    </>
  );
}

export default memo(TrendingUp);
