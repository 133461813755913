import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function TopPerformingAreaIcon({ ...other }: BoxProps) {
  const color = 'currentColor';

  return (
    <Box
      component='svg'
      width='100%'
      height='100%'
      fill='none'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M11.3558 3C8.87131 3.14261 6.54137 4.10808 4.68164 5.76551L7.5578 8.64167C8.64018 7.74057 9.95442 7.19603 11.3558 7.06798V3Z'
        fill={color}
      />
      <path
        d='M6.64515 9.55385L3.76904 6.67773C2.11018 8.53695 1.14352 10.8671 1 13.3522H5.06815C5.19706 11.9505 5.74276 10.6361 6.64515 9.55385Z'
        fill={color}
      />
      <path
        d='M18.9344 13.3522H23.0026C22.8591 10.8671 21.8924 8.53695 20.2335 6.67773L17.3574 9.55385C18.2598 10.6361 18.8055 11.9505 18.9344 13.3522Z'
        fill={color}
      />
      <path
        d='M18.9323 14.6406C18.8419 15.6333 18.5586 16.4613 18.2236 17.1255C18.5504 17.6296 18.8229 18.0556 19.003 18.3385C19.4932 19.109 19.3847 20.098 18.739 20.7438C18.5822 20.9006 18.4031 21.0269 18.209 21.1203L18.8788 21.7902C19.0049 21.9163 19.1702 21.9793 19.3354 21.9793C19.5006 21.9793 19.6658 21.9163 19.7919 21.7902C21.7222 19.8601 22.8478 17.3415 23.0018 14.6406H18.9323Z'
        fill={color}
      />
      <path
        d='M5.06957 14.6406H1C1.15404 17.3415 2.27961 19.8601 4.20989 21.7902C4.33596 21.9163 4.50118 21.9793 4.66639 21.9793C4.83161 21.9793 4.99687 21.9163 5.12294 21.7902L7.07853 19.8345C7.33063 19.5824 7.33063 19.1736 7.07853 18.9214C6.56819 18.4111 5.2827 16.9823 5.06957 14.6406Z'
        fill={color}
      />
      <path
        d='M19.3187 5.76551C17.459 4.10804 15.1291 3.14261 12.6445 3V7.06798C14.0459 7.19603 15.3602 7.74057 16.4426 8.64167L19.3187 5.76551Z'
        fill={color}
      />
      <path
        d='M13.429 12.5746C12.6394 11.785 11.3545 11.785 10.5649 12.5746C9.77525 13.3643 9.77525 14.6491 10.5649 15.4388C11.5624 16.4363 16.465 19.5666 17.0207 19.9202C17.2756 20.0824 17.6089 20.0458 17.8225 19.8322C18.0361 19.6186 18.0727 19.2853 17.9105 19.0304C17.5568 18.4747 14.4266 13.5722 13.429 12.5746Z'
        fill={color}
      />
    </Box>
  );
}

export default memo(TopPerformingAreaIcon);
